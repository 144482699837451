import './App.css';
import THDesktop from './imgs/therapyheals.com_small.webp';
import andyDesktop from './imgs/andyedits.com_small.webp';
import LIFEDesktop from './imgs/lifecounselinggroup.org_small.webp';
import SGVPCDesktop from './imgs/www.sgvpsychotherapycollective.com_small.webp';
import HHEDesktop from './imgs/homeandhybrideducation.org_small.webp';
import ContactForm from './contactForm';


function App() {
  return (
    <div className='font-rubik'>
      <div className='fixed bottom-12 right-12 z-10'><a href='#home'>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-12 h-12 p-1 bg-white rounded-full mix-blend-difference">
          <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 10.5L12 3m0 0l7.5 7.5M12 3v18" />
        </svg>
      </a></div>
      <div className='min-h-screen container mx-auto block' id="home">

        <div className="bg-gradient-to-r from-blue-950 to-amber-800 bg-clip-text absolute top-1/3 pb-4"><h1 className='text-8xl hover:text-transparent transition-colors duration-300 ease-linear'>Logan Bell</h1></div>

        <div className="bg-gradient-to-r from-blue-950 to-amber-800 bg-clip-text absolute bottom-1/4 lg:bottom-1/3 lg:right-40"><nav className="flex sm:justify-center space-x-4">
          {[
            ['Projects', '#projects'],
            ['Contact', '#contact'],
          ].map(([title, url]) => (
            <a href={url} className="px-3 py-2 font-medium text-4xl hover:text-transparent transition-colors duration-300 ease-linear" key={title}>{title}</a>
          ))}
        </nav></div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#0f172a" fillOpacity="1" d="M0,160L60,133.3C120,107,240,53,360,32C480,11,600,21,720,53.3C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path></svg>
      <div className="bg-slate-900 py-5" id="projects">

        <div className="container mx-auto">
          <h2 className="text-6xl mb-20 text-white">Projects</h2>

          <div className='min-h-screen px-4 lg:px-0 lg:grid grid-cols-2 grid-rows-1 pb-5 gap-x-8'>

            <div className="">

              <div className="mb-8">
                <div className="relative hover:z-auto transition-all">
                  <a href='https://therapyheals.com/' target='_blank' rel="noreferrer"><img src={THDesktop} alt="Therapy Heals" className='rounded-lg w-full transition-transform hover:scale-105'></img></a>
                </div>
              </div>
              <div className="mb-8">
                <div className="relative">
                  <a href='https://www.sgvpsychotherapycollective.com/' target='_blank' rel="noreferrer"><img src={SGVPCDesktop} alt="San Gabriel Valley Psychotherapy Collective" className='rounded-lg w-full transition-transform hover:scale-105'></img></a>
                </div>
              </div>
              <div className="mb-8">
                <div className="relative">
                  <a href='https://lifecounselinggroup.org/' target='_blank' rel="noreferrer"><img src={LIFEDesktop} alt="Life Counseling Group" className='rounded-lg w-full transition-transform hover:scale-105'></img></a>
                </div>
              </div>

            </div>
            <div className="lg:mt-20">

              <div className="mb-8">
                <div className="relative">
                  <a href='https://andyedits.com/' target='_blank' rel="noreferrer"><img src={andyDesktop} alt="andyedits" className='rounded-lg w-full transition-transform hover:scale-105'></img></a>
                </div>
              </div>
              <div className="mb-8">
                <div className="relative">
                  <a href='https://homeandhybrideducation.org/' target='_blank' rel="noreferrer"><img src={HHEDesktop} alt="National Association of Home and Hybrid Education" className='rounded-lg w-full transition-transform hover:scale-105'></img></a>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
      <svg xmlns="http://www.w3.org/2000/svg" alt="Scroll to Top" viewBox="0 0 1440 320" className="bg-amber-300"><path fill="#0f172a" fillOpacity="1" d="M0,160L60,133.3C120,107,240,53,360,32C480,11,600,21,720,53.3C840,85,960,139,1080,149.3C1200,160,1320,128,1380,112L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path></svg>
      <div className="py-5 bg-amber-300" id="contact">

        <div className="container mx-auto min-h-screen flex">
          <div className='m-auto px-4 lg:px-0'>
            <h2 className="text-6xl mb-20">Contact</h2>
            <ContactForm />
          </div>
        </div>

      </div>
    </div>
  );
}

export default App;
