import React, { useState } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';

const ContactForm = () => {
    const defaultButton = <button type='submit' className='bg-black text-white hover:text-amber-300 px-4 py-3 text-lg rounded-2xl'>Submit</button>
    const processingButton = <button type='submit' className='bg-black text-white hover:text-amber-300 px-4 py-3 text-lg rounded-2xl' disabled>Submitting...</button>
    const sentButton = <button type='submit' className='bg-emerald-600 text-white hover:text-amber-300 px-4 py-3 text-lg rounded-2xl' disabled>Sent!</button>
    const errorButton = <button type='submit' className='bg-red-600 text-white hover:text-amber-300 px-4 py-3 text-lg rounded-2xl' disabled>Error, please try again later</button>
    const captchaButton = <button type='submit' className='bg-red-600 text-white hover:text-amber-300 px-4 py-3 text-lg rounded-2xl'>Please complete the captcha</button>
    const [submitButton, setSubmitButton] = useState(defaultButton);

    let captchaToken = null;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (captchaToken == null){
            setSubmitButton(captchaButton);
            return
        }
        setSubmitButton(processingButton);
        const { name, email, message } = e.target.elements
        let data = {
            name: name.value,
            email: email.value,
            message: message.value,
            token: captchaToken
        }
        fetch('https://loganis.cool/backend/send', {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then(
            (response) => (response.json())
        ).then((response) => {
            if (response.status === 'success') {
                setSubmitButton(sentButton);
            } else if (response.status === 'fail') {
                setSubmitButton(errorButton);
            } else if (response.status === 'captcha'){
                setSubmitButton(captchaButton);
            }
        });
    };
    return (
        <form id='contact-from' className='w-full block mx-auto'
            onSubmit={handleSubmit}
            method="POST"
            target="_blank">
            <input type='text' className='form-input w-full mb-3 bg-transparent placeholder-black text-black border-0 border-b-2 border-gray-400 focus:border-black focus:ring-0 text-lg' placeholder='Name' id="name" required></input>
            <input type='email' className='form-input w-full mb-3 bg-transparent placeholder-black text-black border-0 border-b-2 border-gray-400 focus:border-black focus:ring-0 text-lg' placeholder='Email' id="email" required></input>
            <textarea className='form-textarea resize-none h-36 w-full mb-3 bg-transparent placeholder-black text-black border-0 border-b-2 border-gray-400 focus:border-black focus:ring-0 text-lg' placeholder='Message' id="message" required></textarea>
            <div className="mb-2">
                <HCaptcha theme="dark" sitekey="03c4d089-fca4-49fc-afd8-ad7da6ac05c6" onVerify={(token) => { console.log(token); captchaToken = token; }} />
            </div>
            {submitButton}
        </form>
    );
};

export default ContactForm;